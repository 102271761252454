const state = {
    mobile: "",
    admin_profile: "",
};

const mutations = {
    SET_MOBILE(state, payload) {
        state.mobile = payload.mobile;
    },
    SET_PROFILE_ADMIN(state, payload) {
        state.admin_profile = payload;
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};