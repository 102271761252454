const state = {
    employee_reward_id: ""
}

const mutations = {
    SET_EMPLOYEEREWARD(state, payload) {
        state.employee_reward_id = payload.employee_reward_id
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};